import React from 'react'
import Layout from '../../../components/layout'

const ApplyNowIntroPage = () => {
  return (
    <Layout>
      <section className="container">
        <div className="row">
          <div className="col-md-12 mb-5">
            <h1 className='na-head'>APPLY TO THE SOCIAL MOBILITY NETWORK</h1>
            <p>Thank you for your interest in the Social Mobility Network by upReach.</p>
            <p>The Social Mobility Network is an invaluable resource that will provide you with access to hundreds of industry professionals from a wide range of sectors who are all eager to support you with your career development via Expert Advice sessions, Career Chats, and Mock Interviews.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <h1 className='na-head'>ELIGIBILITY</h1>
            <p>To be eligible to join the Social Mobility Network, you must meet the following criteria:</p>
            <ul className='ml-4'>
              <li><p className='mb-2'>Household income <b>below £42,620</b></p></li>
              <li><p className='mb-2'>Attended <b>UK state schools only</b> (or since age 14 if educated abroad before then)</p></li>
              <li><p className='mb-2'>Achieved <b>at least BBB</b> in top 3 A-Levels on a <b>contextualised basis</b> (*see below) or an equivalent qualification</p></li>
              <li><p className='mb-2'>Achieved at least a <b>C in GCSE Maths and English</b></p></li>
              <li><p className='mb-2'>Currently an <b>undergraduate over the age of 18</b> that is in full-time study at a UK university**</p></li>
              <li><p className='mb-2'>Should not be studying social work, nursing, dentistry or medicine</p></li>
            </ul>
            <p>If you meet the above criteria, start your application today! If you are unsure about whether to apply, feel free to get in touch or complete the application form and we can assess your eligibility. The short application form takes 5-10 minutes to complete.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <button type="button" class="btn btn-primary btn-md mt-2 mb-4" onClick={() =>  window.location.href = '/apply-now/submit'}>START APPLICATION</button>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <p>As a reminder, <b>if you are already an upReach Associate, you do not need to apply</b> to the Social Mobility Network. Instead, <a href="https://app.socialmobilitynetwork.org.uk/welcome">you can click here.</a> If you are not an upReach Associate, you can apply to join the Social Mobility Network below.</p>
            <p>*Your REALrating gives you a better indication of your academic capabilities, taking into account factors such as schooling that can affect grades. To find out more, please visit realrating.co.uk</p>
            <p>**If you have completed your undergraduate course of study, then you are not eligible to apply.</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default ApplyNowIntroPage